import React from "react"

/** components */
import { ColorPalette } from "../Theme"

/** svg */
import NorhartIcon from "../../../assets/norhart-icon.svg"
import NorhartDarkCircleIcon from "../../../assets/norhart-dark-circle-icon.svg"

/** props */
interface Props {
  colorPalette: ColorPalette
}

/** const */
const PrivacyStatementSection: React.FC<Props> = (props) => {
  return (
    <section className="container-fluid sectionBaseColor pt-5 pb-5">
      <div className="container pl-3 pr-3">
        <div className="container pb-5"></div>
        <div className="text-center pb-2">
          <NorhartDarkCircleIcon />
        </div>
        <div
          className="contentHeroTitle hidden-sm hidden-md hidden-lg hidden-xl text-sm-center"
          style={{ fontSize: "15px" }}
        >
          Norhart Legal
        </div>
        <hr
          className="contentHeroTitle hidden-sm hidden-md hidden-lg hidden-xl style-line mt-4 mb-4"
          style={{ borderColor: "rgba(0, 0, 0, 0.2)" }}
        />
        <div className="contentHeroSpace hidden-xs"></div>

        <h1 className="contentHeroTitle" style={{ color: "#365c70" }}>
          We Value Your Privacy at Norhart
        </h1>
        <h2 className="contentHeroSubTitle" style={{ color: "rgba(83,118,136,0.8" }}>
          Norhart is committed to your privacy. Read our customer Privacy Policy for a transparent explanation of how we
          collect, use, disclose, transfer, and store your information.
        </h2>

        <p />
        <p />

        <div className="contentHeroSubTitle text-start m-0" style={{ color: "rgba(83,118,136,0.8" }}>
          <blockquote className="blockquote text-start legalTitle">
            NORHART, INC. (“Norhart”) PRIVACY POLICY (“Privacy Policy”)
          </blockquote>
          <blockquote className="blockquote text-start legalTitle">(Effective Date: Jan 1, 2024)</blockquote>

          <blockquote className="blockquote text-start legalLink">
            At Norhart, we value your privacy, and protecting it is our priority. Please read our customer Privacy
            Policy for an explanation of how we collect, use, disclose, transfer, and store your personal information.
            <p /> <p />
            This Privacy Policy governs your use of www.norhart.com and other Norhart websites such as
            invest.norhart.com (collectively, the “Websites”) and other products or services that we provide
            (collectively, the “Services”). By using our Websites or Services, you are acknowledging that you have read,
            understood, and agree to our Privacy Policy. IF YOU DO NOT AGREE TO THIS PRIVACY POLICY, YOU MAY NOT ACCESS
            THE WEBSITES OR THE SERVICES.
          </blockquote>

          <blockquote className="blockquote text-start legalTitle">
            I. Personal Information We Collect and Receive.
          </blockquote>
          <hr />

          <blockquote className="blockquote text-start">
            A. Personal Information We Collect and Receive. In order for you to access and use our Websites and
            Services, we may collect and process certain types of personal information as you interact with us.
            Depending on your activities on our Websites and Services this may include:
          </blockquote>

          <blockquote className="blockquote text-start" style={{ marginLeft: "50px" }}>
            <ul className="list-group ms-3">
              <li>
                Personal information you provide to us directly or that others provide about you, such as your name,
                address, email address, phone number, username, password, date of birth, gender, and household income;
              </li>
              <br />
              <li>
                Transaction information you provide to us or that others provide about you, such as payment card
                information or information about the Services purchased, used, or considered;
              </li>
              <br />
              <li>
                Financial information that you provide to us or that others provide about you, such as information about
                your financial and investment history and the information you authorize us to collect from any financial
                accounts that you allow to be connected to the Services;
              </li>
              <br />
              <li>
                Information about your activity when using our Websites and Services. This may include information we
                collect from the device you use, such as your IP address, the type of device or browser you use, and
                information about your interaction with the Websites and Services, including applications, widgets, and
                advertisements;
              </li>
              <br />
              <li>
                Information about your geolocation, such as physical location based on IP address, device data, or
                region;
              </li>
              <br />
              <li>
                Professional or employment information you provide to us directly or that others provide about you;{" "}
              </li>
              <br />
              <li>
                Physical characteristics or descriptions. For example, if you provide a photograph of yourself or your
                ID; and
              </li>
              <br />
              <li>
                Inferences we draw from your personal information, such as a profile reflecting your preferences,
                characteristics, and behavior with our Websites or Services.
              </li>
            </ul>
          </blockquote>

          <blockquote className="blockquote text-start">
            Please note that personal information for the purposes of this Privacy Policy does not include aggregate
            information from which individual identities have been removed.
          </blockquote>

          <blockquote className="blockquote text-start">
            B. Personal Information from Children. Norhart does not knowingly solicit or collect any personal
            information from anyone under 13 years of age, and children under 13 are not permitted to register for or
            use our Websites or Services. If we become aware that a child under the age of 13 has provided us with their
            personal information, we will delete it.
          </blockquote>

          <blockquote className="blockquote text-start legalTitle">II. How We Collect Personal Information.</blockquote>
          <hr />

          <blockquote className="blockquote text-start">
            Norhart collects personal information directly from you. In order to provide the Services, Norhart also
            frequently receives personal information about you from others, such as indirectly from your financial
            institution, as further described below:
          </blockquote>

          <blockquote className="blockquote text-start">
            A. Personal Information You Provide Directly. We collect your personal information that you provide to us
            directly when you take actions such as filling out a form on our Websites; registering for an account with
            us; contacting us by email, letter, phone, or chat; interacting with us on social media; interacting with
            our Websites or Services; or engaging in a transaction with us.
          </blockquote>

          <blockquote className="blockquote text-start">
            B. Personal Information Provided About You by Others. We also collect personal information about you
            provided to us by others in order to enable you to use or benefit from our Services or Website. For example:
          </blockquote>

          <blockquote className="blockquote text-start pl-5" style={{ marginLeft: "50px" }}>
            <ul className="list-group ms-3">
              <li>
                Norhart uses Plaid Inc. (“Plaid”) to gather your personal and financial information from financial
                institutions. By using the Services, you grant Norhart and Plaid the right, power, and authority to act
                on your behalf to access and receive your personal and financial information from your relevant
                financial institution. You also agree to Plaid processing your personal and financial information in
                accordance with the Plaid End User Privacy Policy.
              </li>
              <br />
              <li>Our partners and service providers may also provide us with personal information about you.</li>
            </ul>
          </blockquote>

          <blockquote className="blockquote text-start">
            C. Automatically Collected Personal Information – Browsers and Devices. We and our Websites collect
            information that is sent to us automatically by your device or web browser, such as your IP address, browser
            type, and information about your browsing history.:
          </blockquote>

          <blockquote className="blockquote text-start">
            D. Automatically Collected Personal Information - Cookies and Tracers. Our Websites may use cookies and
            similar technologies (e.g., web beacons, pixels, ad tags, device identifiers, and other tracking tools) to
            recognize you and/or your device(s) on, off, and across the Websites and different Services and devices.
            Cookies are used to enable certain website features and functionalities. A cookie is a small file that is
            placed onto your device (e.g., computer, smartphone or other electronic device) that stores information when
            you visit a website. Cookies and these similar technologies also help us segment and analyze usage of our
            Websites and Services and may help us or third parties serve advertising to you on the Websites or across
            the Internet. These technologies may also combine information they collect from your interaction with the
            Websites or Services with information they collect from other sources. We are not responsible for any third
            parties’ use of cookies or other tracking technologies. Please contact these third parties directly for more
            information about their privacy policies and your choices regarding their tracking technologies. You can
            control certain cookies through your browser settings and other tools. Please note that if you choose to
            decline cookies, you may not be able to fully experience our Website or Services.
          </blockquote>

          <blockquote className="blockquote text-start legalTitle">III. How We Use Personal Information.</blockquote>
          <hr />

          <blockquote className="blockquote text-start">
            Norhart may use the personal information we collect for the following purposes:
          </blockquote>

          <blockquote className="blockquote text-start pl-5" style={{ marginLeft: "50px" }}>
            <ul className="list-group ms-3">
              <li>To meet any contractual obligations with you;</li>
              <br />
              <li>To provide and deliver our Services and Websites and related support;</li>
              <br />
              <li>To allow you to log into and access your account;</li>
              <br />
              <li>To process any purchases, transactions, or payments made on our Websites;</li>
              <br />
              <li>
                To keep your information and our systems secure such as by detecting security incidents, and to be able
                to verify your identity and contact information;
              </li>
              <br />
              <li>
                To alert you about updates, special offers, and other news, services, products, and information from us,
                our affiliates, partners, or others that may be of interest to you;
              </li>
              <br />
              <li>
                For testing, research, analysis, and product development, including to develop and improve our content,
                Websites, Services, and advertising;{" "}
              </li>
              <br />
              <li>
                To ensure our content, Websites, and Services are functional, efficient, and relevant to you and your
                interests;{" "}
              </li>
              <br />
              <li>To respond to your inquiries and requests;</li>
              <br />
              <li>
                To enforce this Privacy Policy, our Terms and Conditions, and our rights; and to protect our business
                interests and the interests and rights of third parties;{" "}
              </li>

              <br />
              <li>
                To respond to law enforcement requests and as required by applicable law, court order, or governmental
                regulations; and{" "}
              </li>
              <br />
              <li>
                To evaluate or conduct a merger, divestiture, restructuring, reorganization, dissolution, or other sale
                or transfer of some or all of our assets.
              </li>
            </ul>
          </blockquote>

          <blockquote className="blockquote text-start legalTitle">
            IV. How Personal Information May Be Disclosed.
          </blockquote>
          <hr />

          <blockquote className="blockquote text-start">
            Norhart does not sell or rent any of your personal information. We only disclose your personal information
            for the following business and operational purposes:
          </blockquote>

          <blockquote className="blockquote text-start pl-5" style={{ marginLeft: "50px" }}>
            <ul className="list-group ms-3">
              <li>
                To our service providers: We rely on certain trusted third-party service providers to provide, process,
                improve, promote, or protect our Websites and Services. These service providers do things like help us
                store your data, process your payments, deliver the Websites and/or Services to you, improve our
                advertising, and secure our systems.
              </li>
              <br />
              <li>
                To other entities related to Norhart: We may disclose personal information to our related companies,
                such as affiliates, subsidiaries, or other companies under common control with us.
              </li>
              <br />
              <li>
                To our business partners who may be of interest to you: We may disclose personal information to our
                third party business partners or allow those partners to collect information about you (including
                through the use of cookies, pixels, or similar technologies) for their own business or marketing
                purposes, including to promote their products or services.
              </li>
              <br />
              <li>
                In the event of a change in control: We may disclose personal information to other companies for the
                purpose of evaluating or conducting a merger, divestiture, restructuring, reorganization, dissolution,
                or other sale or transfer of some or all of our assets.
              </li>
              <br />
              <li>
                As necessary or otherwise required by law: We also reserve the right to disclose personal information
                when it is reasonably necessary to conduct our business, protect Norhart’s rights and property, protect
                personal or public safety, or to comply with the law.
              </li>
              ={" "}
            </ul>
          </blockquote>

          <blockquote className="blockquote text-start legalTitle">
            V. Protection of Your Personal Information.
          </blockquote>
          <hr />

          <blockquote className="blockquote text-start">
            We take the security of your personal information seriously. Norhart has therefore put in place commercially
            reasonable measures to safeguard and secure the personal information that we collect.
            <p />
            <p />
            Please remember, however, that the safety and security of your personal information also depend on you.
            Where you have chosen, or where we have given you, a user name and password for access to certain parts of
            the Websites and Services, you are responsible for keeping the user name and password confidential and for
            using secure connections. No electronic storage or method of transmission over the internet or a wireless
            network is completely secure, so Norhart cannot guarantee the absolute security of your personal
            information. As a result, you acknowledge that: (a) there are security and privacy limitations inherent to
            the Internet that are beyond Norhart’s control; and (b) the security, integrity, and privacy of your
            personal information collected or received by Norhart cannot be guaranteed.
          </blockquote>

          <blockquote className="blockquote text-start legalTitle">VI. Control Norhart Communications.</blockquote>
          <hr />

          <blockquote className="blockquote text-start">
            We respect your privacy and give you an opportunity to opt-out of certain Norhart communications. From time
            to time, Norhart may contact you via email for the purpose of providing announcements, promotional offers,
            alerts, confirmations, surveys, and/or general communications. In order to improve our Services, we may
            receive a notification when you open an email from Norhart or click on a link therein
            <p />
            <p />
            If you would like to stop receiving marketing or promotional communications via email from Norhart, you may
            opt out of such communications by replying “STOP” or clicking on the “unsubscribe” button.
            <p />
            <p />
            By providing your phone number, you agree to receive text messages from Norhart regarding updates, offers,
            and promotions. Reply “STOP” to opt out. Message and data rates may apply.
          </blockquote>

          <blockquote className="blockquote text-start legalTitle">VII. Data Transfers.</blockquote>
          <hr />

          <blockquote className="blockquote text-start">
            Because Norhart is a US-based company, your personal information may be collected and processed in the
            United States. Additionally, as part of the Website and Services offered to you, information that we receive
            about you may be transferred to and stored in one or more countries other than where you currently reside.
            For example, this may occur if any of our servers or third-party service providers are located in a country
            other than yours. These countries may have different data protection laws than your current residence.
            However, if we transfer your personal information in this way, we will put in place appropriate protection
            to make sure it is treated in line with this Privacy Policy.
          </blockquote>

          <blockquote className="blockquote text-start legalTitle">VIII. Mobile and Email Notifications.</blockquote>
          <hr />

          <blockquote className="blockquote text-start">
            We try to give you the ability to opt-in/opt-out to any marketing communications we may send. For example,
            we may send you email relating to the Services we offer. You may also elect to receive certain marketing
            communications in accordance with your preferences. We will provide you with an opportunity to opt-out
            and/or unsubscribe from such communications.
          </blockquote>

          <blockquote className="blockquote text-start legalTitle">IX. Retention.</blockquote>
          <hr />

          <blockquote className="blockquote text-start">
            We will keep your personal information for as long as is necessary to perform the processing activity for
            that information, as long as is set out in any relevant contract you hold with us, or as required or allowed
            by law. The relevant retention period is determined on a case-by-case basis because it depends on things
            like the nature of the data, why it is collected, why it is processed, and any legal or operational
            retention needs. For example, we may retain your data for as long as you use our Services. When the relevant
            processing activity has been completed, we may destroy your data or, alternatively, anonymize the data such
            that the data is not personally identifiable. We do not have any obligation or liability to you or any other
            party with respect to personal information that is deleted or anonymized.
          </blockquote>

          <blockquote className="blockquote text-start legalTitle">X. Special Notice to Nevada Residents.</blockquote>
          <hr />

          <blockquote className="blockquote text-start">
            We do not sell, rent, or lease your personally identifiable information to third parties. However, if you
            are a resident of Nevada and would like to submit a request not to sell your personally identifiable
            information, you may do so by emailing us at{" "}
            <a href="mailto:contact@norhart.com" target="_blank">
              contact@norhart.com
            </a>
            .
          </blockquote>

          <blockquote className="blockquote text-start legalTitle">XI. Questions, Comments, or Complaints.</blockquote>
          <hr />

          <blockquote className="blockquote text-start">
            If you have questions or comments about this Privacy Policy, we invite you to contact us at
            contact@norhart.com.
          </blockquote>

          <blockquote className="blockquote text-start legalTitle">
            XII. Updates and Changes to this Privacy Policy.
          </blockquote>
          <hr />

          <blockquote className="blockquote text-start">
            This Privacy Policy is effective as of Jan 1, 2024. We may update or change this Privacy Policy from time to
            time. Your continued use of the Websites or Services after such changes will constitute your acknowledgement
            of and agreement to the modified Privacy Policy.
          </blockquote>
        </div>
      </div>
    </section>
  )
}
/** export */
export default PrivacyStatementSection
