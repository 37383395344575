import React from "react"
import { Helmet } from "react-helmet"
import config from "../../../site.config"

/** components */
import Layout from "../../components/Layout"
import { theme } from "../../components/Theme"

/** static sections */
import PrivacyStatementSection from "../../components/static-sections/PrivacyStatementSection"

/** dynamic sections */
import HeroTopSection from "../../components/dynamic-sections/HeroTopSection"
import RibbonSection from "../../components/dynamic-sections/RibbonSection"

/** svg */
import NorhartDarkCircleIcon from "../../assets/norhart-dark-circle-icon.svg"

/** color theme */
const colorPalette = theme.colorPalettes.blue

/** props */
interface Props {
  data: any
}

/** const */
const PrivacyPage: React.FC<Props> = (props) => {
  const ldJson = {
    "@context": "http://schema.org",
    "@type": "BreadcrumbList",
    itemListElement: [
      {
        "@type": "ListItem",
        position: 1,
        item: {
          "@id": "/legal/privacy/",
          name: "Legal | Norhart",
          image: `${config.siteMetadata.siteUrl}/legal/norhart-legal-hero-open-graph.png`,
        },
      },
    ],
  }

  return (
    <Layout
      title="Legal Privacy Statement | Norhart"
      description="Norhart is committed to your privacy. Read our customer Privacy Policy for a transparent explanation of how we collect, use, disclose, transfer, and store your information."
      keywords="norhart, luxury, innovative, smart, technology, apartment, apartments, building, buildings, mn, minnesota, minneapolis, st paul, rent, rentals, resident, residents, forest lake, blaine, circle pines, lexington, coon rapids, Oakdale, encore apartments, lexington lofts apartments, Oakdale apartments, gateway green apartments, mill pond apartments, birchview apartments, legacy pointe apartments, heritage heights apartments, springwood apartments, greystone apartments, northbrook apartments, construction, norhart construction, lean construction"
      imageTwitter={`${config.siteMetadata.siteUrl}/legal/norhart-legal-hero-twitter-card.png`}
      imageOpenGraph={`${config.siteMetadata.siteUrl}/legal/norhart-legal-hero-open-graph.png`}
      colorPalette={colorPalette}
    >
      <HeroTopSection
        title="Privacy Policy"
        subtitle="Complete Transparency End-to-End"
        imageTitle="Norhart Privacy Statement"
        image="/legal/norhart-legal-hero.png"
        colorPalette={colorPalette}
      />

      <RibbonSection title="Norhart Legal" tagLine="We are committed to your privacy" colorPalette={colorPalette} />

      <Helmet>
        <script type="application/ld+json">{JSON.stringify(ldJson)}</script>
      </Helmet>

      <PrivacyStatementSection colorPalette={colorPalette} />
    </Layout>
  )
}

/** export */
/** export */
export default PrivacyPage
